import React, { useState } from 'react';
import { Divide as Hamburger } from 'hamburger-react';
import logo from "../img/lucidweb_logo.png";
import { Link } from 'react-scroll';
import useContactFormToggle from './ContactForm';
import { CSSTransition } from 'react-transition-group';
import ContactForms from "./contact/ContactForms";
// import {NavLink} from "react-router-dom";

const Header = () => {
    const { showContactForm, contactFormAnimation, toggleContactForm } = useContactFormToggle();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <header className="fixed top-0 left-0 w-full bg-gray-900 p-2 flex justify-between items-center shadow-md z-40 text-lg">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full flex items-center justify-center">
                        <a href="/">
                            <img src={logo} alt="LucidWeb Logo" className="w-full h-full object-cover rounded-full" />
                        </a>
                    </div>
                    <div className="text-3xl text-white font-bold ml-4">
                        Lucid<span className="text-blue-500">Web</span>
                    </div>
                </div>

                <div className="lg:hidden">
                    <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} color="#ffffff" />
                </div>

                <nav className="hidden lg:flex justify-center text-center text-xl">
                    <Link to="AboutUs" smooth={true} duration={800} className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out">
                        Par Mums
                    </Link>
                    <Link to="AboutCompany" smooth={true} duration={800} className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out">
                        Par Kompāniju
                    </Link>
                    <Link to="WhatWeOffer" smooth={true} duration={800} className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out">
                        Pakalpojumi
                    </Link>
                    {/*<NavLink to="/demo" className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out">*/}
                    {/*    Demos*/}
                    {/*</NavLink>*/}
                </nav>

                <div className="hidden lg:block mr-16 text-white text-xl">
                    <button onClick={toggleContactForm} className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out">
                        Sazināties
                    </button>
                </div>
            </header>

            {/* Mobile Menu */}
            <CSSTransition in={isMenuOpen} timeout={300} classNames="mobile-menu" unmountOnExit>
                <nav className="fixed top-16 left-0 w-full bg-gray-900 text-center p-4 space-y-4 lg:hidden z-30">
                    <Link to="AboutUs" smooth={true} duration={800} className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out" onClick={toggleMenu}>
                        Par Mums
                    </Link>
                    <Link to="AboutCompany" smooth={true} duration={800} className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out" onClick={toggleMenu}>
                        Par Kompāniju
                    </Link>
                    <Link to="WhatWeOffer" smooth={true} duration={800} className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out" onClick={toggleMenu}>
                        Pakalpojumi
                    </Link>
                    {/*<NavLink to="/demo" className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out" onClick={toggleMenu}>*/}
                    {/*    Demos*/}
                    {/*</NavLink>*/}
                    <button className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out w-full text-center" onClick={() => { toggleMenu(); toggleContactForm(); }}>
                        Sazināties
                    </button>
                </nav>
            </CSSTransition>

            {/* ContactForms Component */}
            <ContactForms
                showContactForms={showContactForm}
                contactFormAnimation={contactFormAnimation}
                toggleContactForms={toggleContactForm}
            />
        </>
    );
};

export default Header;

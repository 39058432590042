import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import './index.css';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Home from './components/Home';
import CompanyMembers from './components/CompanyMembers';
import AboutCompany from "./components/AboutCompany";
import WhatWeOffer from "./components/WhatWeOffer";
import Prices from './components/Prices';
import FallingStars from './components/FallingStars';
import Footer from './components/Footer';
// import DemoPage from './components/Demos';

function Layout() {
    const location = useLocation();  // Get the current route

    // Conditionally render the header and footer based on the route
    const showHeaderAndFooter = location.pathname !== '/cenas';

    return (
        <div className="min-h-screen bg-gray-900 text-white relative">
            <FallingStars/>
            <div className="relative z-10">
                {showHeaderAndFooter && <Header/>}
                <Routes>
                    <Route path="/" element={
                        <>
                            <Home/>
                            <AboutUs/>
                            <AboutCompany/>
                            <WhatWeOffer/>
                            <CompanyMembers/>
                        </>
                    }/>
                    <Route path="/cenas" element={<Prices/>}/>
                    {/*<Route path="/demo" element={<DemoPage />} />*/}
                </Routes>
                {showHeaderAndFooter && <Footer/>}
            </div>
        </div>
    );
}

function App() {
    return (
        <Router>
            <Layout />
        </Router>
    );
}

export default App;
export { Prices };  // Export Prices separately
import React from 'react';

const Toast = ({ message, type, onClose }) => {
    return (
        <div className={`fixed top-4 right-4 p-4 rounded-md ${type === 'error' ? 'bg-red-500' : 'bg-green-500'} text-white`}>
            <span>{message}</span>
            <button onClick={onClose} className="ml-2">✖</button>
        </div>
    );
};

export default Toast;

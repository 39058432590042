import React from "react";
import {FaFacebook, FaTwitter, FaInstagram} from 'react-icons/fa';
import useContactFormToggle from './ContactForm';
import ContactForms from './contact/ContactForms';
import {motion} from 'framer-motion';

const Footer = () => {
    const {showContactForm, contactFormAnimation, toggleContactForm} = useContactFormToggle();

    return (
        <>
            <footer
                className="bg-gray-900 text-gray-100 py-8 px-4 md:px-8 relative before:absolute before:inset-x-0 before:top-[-20px] before:h-8 before:bg-gradient-to-b before:from-gray-900 before:to-transparent before:shadow-md before:z-10">
                <div className="container mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-8 text-center md:text-left">

                    {/* First Section with Slide-In Animation */}
                    <motion.div
                        className="md:col-span-1"
                        initial={{x: '-100%'}}
                        whileInView={{opacity: 1, x: 0}}
                        transition={{type: 'spring', stiffness: 100, damping: 25}}
                        viewport={{once: true}}
                    >
                        <h2 className="text-2xl md:text-3xl font-bold mb-4">Sazinies ar mums</h2>
                        <p className="mb-6 text-gray-400 px-10 md:px-0">
                            Mēs specializējamies vietņu dizaina uzlabošanā un tā izveidē no nulles, nodrošinot
                            mūsdienīgus, lietotājiem draudzīgus risinājumus, kas pielāgoti jūsu zīmola vajadzībām.
                        </p>

                        {/* Social Media Icons */}
                        <div className="flex flex-col md:flex-row justify-center md:justify-start space-x-4 mt-6">
                            <div className="flex flex-row space-x-4 justify-center items-center">
                                <a href="https://www.facebook.com/people/LucidWeb/61568128645640/" target="_blank"
                                   rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100">
                                    <FaFacebook size={24}/>
                                </a>
                                <a href="https://www.instagram.com/lucidiousweb/" target="_blank"
                                   rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100">
                                    <FaInstagram size={24}/>
                                </a>
                                <a href="https://x.com/LucidiousWeb" target="_blank" rel="noopener noreferrer"
                                   className="text-gray-400 hover:text-gray-100">
                                    <FaTwitter size={24}/>
                                </a>
                            </div>
                            <p className="text-blueCustom text-center pt-10 md:pt-0 md:pl-5 underline underline-offset-8 decoration-gray-400">
                                lucidious.websites@gmail.com
                            </p>
                        </div>
                    </motion.div>

                    {/* Second Section */}
                    <div
                        className="md:col-span-1 lg:col-span-2 flex flex-col items-center md:flex-row space-y-8 md:space-y-0 md:space-x-8 justify-end">
                        <button
                            className="bg-gray-800 hover:bg-gray-900 p-6 rounded-lg relative shadow-lg border border-indigo-300 w-full max-w-sm md:max-w-xs flex flex-col items-center cursor-pointer duration-500 ease-in-out"
                            onClick={toggleContactForm}>
                            <span className="hover:text-blue-500 text-xl">Sazināties</span>
                            <div className="absolute inset-0 -right-4 -bottom-4 bg-gray-700 rounded-lg z-[-1]"></div>
                        </button>
                    </div>
                </div>
            </footer>

            {/* ContactForms Component */}
            <ContactForms
                showContactForms={showContactForm}
                contactFormAnimation={contactFormAnimation}
                toggleContactForms={toggleContactForm}
            />
        </>
    );
};

export default Footer;


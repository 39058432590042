import React from 'react';
import {motion} from 'framer-motion';

const WhatWeOffer = () => {
    return (
        <div id="WhatWeOffer" className="min-h-screen flex flex-col items-center justify-center p-4 sm:p-8">
            <motion.div
                className="text-2xl md:text-5xl text-white font-bold text-center mb-5"
                initial={{opacity: 0}}
                whileInView={{opacity: 1}}
                transition={{duration: 2}}
                viewport={{once: true}}
            >
                <h1>
                    Ko <span className="text-blueCustom">Mēs Piedāvājam?</span>
                </h1>
            </motion.div>
            <div className="w-20 sm:w-40 h-1 bg-white"></div>

            {/* Responsive Grid Layout */}
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 sm:gap-12 mt-16 sm:mt-28">
                {/* Section 1 with animation */}
                <motion.div
                    className="flex flex-col items-center text-center gap-5"
                    initial={{x: '-100%', opacity: 0}}
                    whileInView={{x: 0, opacity: 1}}
                    transition={{type: 'spring', stiffness: 100, damping: 25, delay: 0.4}}
                    viewport={{once: true}}
                >
                    <h3 className="text-lg sm:text-2xl font-bold">
                        Mājaslapu Izveide
                    </h3>
                    <p className="text-sm md:text-base px-2 sm:px-10 lg:px-20">
                        Mēs piedāvājam vairāku veidu mājaslapu izstrādi, tā var būt vienkārša
                        lapa ar informatīvu tekstu un attēliem, vai pilnveidota mājaslapa, kas sadalīta vairākās sadaļās
                        ar vairākām
                        animācijām un/vai bildēm. Jūs mums pastāstat ko vēlaties, un mēs
                        to realizēsim <span className="text-blueCustom font-bold">Jūsu Sapņus</span>.
                    </p>
                    <p className="text-5xl">*</p>
                </motion.div>

                {/* Section 2 with animation */}
                <motion.div
                    className="flex flex-col items-center text-center gap-5"
                    initial={{x: '-100%', opacity: 0}}
                    whileInView={{x: 0, opacity: 1}}
                    transition={{type: 'spring', stiffness: 100, damping: 25, delay: 0.6}}
                    viewport={{once: true}}
                >
                    <h3 className="text-lg sm:text-2xl font-bold">
                        Uzturēšana
                    </h3>
                    <p className="text-sm sm:text-base px-2 sm:px-10 lg:px-20">
                        Pēc mājaslapas izstrādes nepieciešams to arī uzturēt, un tur mēs arī varam palīdzēt. Mēs
                        piedāvājam pilnveidotu mājaslapas uzturēšanu - datu atjaunināšana,
                        vietnes atjauninājumi, problēmu risināšana utt. Viss lai <span
                        className="text-blueCustom font-bold">Jūsu Sapņi</span> piepildītos.
                    </p>
                    <p className="text-5xl">*</p>
                </motion.div>

                {/* Section 3 with animation */}
                <motion.div
                    className="flex flex-col items-center text-center gap-5"
                    initial={{x: '-100%', opacity: 0}}
                    whileInView={{x: 0, opacity: 1}}
                    transition={{type: 'spring', stiffness: 100, damping: 25, delay: 0.8}}
                    viewport={{once: true}}
                >
                    <h3 className="text-lg sm:text-2xl font-bold">
                        Atsaucīgums
                    </h3>
                    <p className="text-sm sm:text-base px-2 sm:px-10 lg:px-20">
                        Viens no mūsu svarīgākajiem principiem pēc kuriem vadamies ir atsaucīgums. Problēmu rašanās
                        brīdīm ātri un efektīvi cenšamies tās atrisināt, lai nodrošinātu
                        Jums mājaslapas veiktspēju un darbību. <span className="text-red-600 font-bold">24h</span> laikā
                        sniegsim Jums atbildi/risinājumu priekš problēmas. Risināsim visu,
                        lai <span className="text-blueCustom font-bold">Jūsu Sapņi</span> nesalūztu.
                    </p>
                    <p className="text-5xl">*</p>
                </motion.div>
            </div>
        </div>
    );
}

export default WhatWeOffer;

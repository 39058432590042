import { useState, useCallback } from 'react';

const useContactFormToggle = () => {
    const [showContactForm, setShowContactForm] = useState(false);
    const [contactFormAnimation, setContactFormAnimation] = useState('');

    const toggleContactForm = useCallback(() => {
        if (!showContactForm) {
            setShowContactForm(true);
            setContactFormAnimation('contact-form-enter');
            setTimeout(() => setContactFormAnimation('contact-form-enter-active'), 10);
        } else {
            setContactFormAnimation('contact-form-exit');
            setTimeout(() => {
                setShowContactForm(false);
                setContactFormAnimation('');
            }, 500);
        }
    }, [showContactForm]);

    return {
        showContactForm,
        contactFormAnimation,
        toggleContactForm,
    };
};

export default useContactFormToggle;


import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { isEmailValid } from './helpers';
import emailjs from 'emailjs-com';
import Toast from './Toast';

const ContactForms = ({ showContactForms, contactFormAnimation, toggleContactForms }) => {
    const [form, setForm] = useState({ name: '', email: '', phone: '', message: '' });
    const [toast, setToast] = useState({ message: '', type: '' });

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setToast({ message: '', type: '' });

        // Input validation
        if (!form.name || !form.email || !form.message || !form.phone) {
            setToast({ message: 'Visus ievadlaukus ir nepieciešams aizpildīt.', type: 'error' });
            return;
        }

        // Email validation
        if (!isEmailValid(form.email)) {
            setToast({ message: 'Lūdzu ievadiet pareizu e-pastu.', type: 'error' });
            return;
        }

        // Check for minimum character length in message
        if (form.message.length < 16) {
            setToast({ message: 'E-pastam jābut vismaz 16 rakstzīmēm.', type: 'error' });
            return;
        }

        // Sending email through EmailJS
        emailjs.send('service_xq5lfa4', 'template_0grvr6i', {
            name: form.name,
            email: form.email,
            phone: form.phone,
            message: form.message
        }, 'l17dt6nbvrHGWD8NP')
            .then(() => {
                setToast({ message: 'Tavs e-pasts ir veiksmīgi nosūtīts!', type: 'success' });
                setForm({ name: '', email: '', phone: '', message: '' }); // Reset form
            })
            .catch((error) => {
                setToast({ message: 'Neveiksmīgi nosūtīts e-pasts. Mēģini vēlreiz.', type: 'error' });
                console.log('Failed to send email:', error.text);
            });
    };

    return (
        showContactForms && (
            <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${contactFormAnimation}`}>
                <div className="bg-gray-900 p-4 md:p-8 rounded-lg shadow-lg space-y-4 relative min-w-80 md:min-w-96 mx-4">
                    <button className="absolute top-2 right-2 md:top-4 md:right-4 text-white hover:text-gray-500 text-2xl" onClick={toggleContactForms}>
                        <IoMdClose className="text-3xl" />
                    </button>
                    <h1 className="text-2xl md:text-3xl text-white font-bold">Sazinies ar mums</h1>

                    <form onSubmit={handleSubmit} className="flex flex-col w-full">
                        <input type="text" name="name" value={form.name} onChange={handleChange} placeholder="Vārds, Uzvārds" className="mb-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-48 md:min-w-96" />
                        <input type="email" name="email" value={form.email} onChange={handleChange} placeholder="E-pasts" className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-48 md:min-w-96" />
                        <input type="text" name="phone" value={form.phone} onChange={handleChange} placeholder="Telefona Numurs" className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-48 md:min-w-96" />
                        <textarea name="message" value={form.message} onChange={handleChange} placeholder="Ziņojums" rows="6" className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-48 md:min-w-96" />
                        <button type="submit" className="text-gray-200 bg-blue-500 shadow-lg shadow-blue-500/50 px-4 md:px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">Send</button>
                    </form>

                    {/* Show Toast */}
                    {toast.message && (
                        <Toast
                            message={toast.message}
                            type={toast.type}
                            onClose={() => setToast({ message: '', type: '' })}
                        />
                    )}
                </div>
            </div>
        )
    );
};

export default ContactForms;

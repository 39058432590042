import React from 'react';
import {motion} from 'framer-motion';

const AboutCompany = () => {
    return (
        <div id="AboutCompany"
             className="min-h-screen flex flex-col items-center justify-center bg-gray-900 p-6 md:p-12 lg:p-16 xl:p-24">
            <div className="text-2xl sm:text-4xl lg:text-5xl text-white font-bold text-center mb-6 sm:mb-10 lg:mb-12">
                <motion.h1
                    initial={{opacity: 0, x: -100}}
                    whileInView={{opacity: 1, x: 0}}
                    transition={{duration: 1}}
                    viewport={{once: true}}
                >
                    Par <span className="text-blueCustom">Mūsu Kompāniju</span>
                </motion.h1>
            </div>
            <div
                className="text-base md:text-xl lg:text-2xl text-gray-300 max-w-xl md:max-w-2xl lg:max-w-4xl text-center leading-relaxed sm:leading-loose">
                <motion.p
                    initial={{opacity: 0, x: -100}}
                    whileInView={{opacity: 1, x: 0}}
                    transition={{duration: 1, delay: 0.2}}
                    viewport={{once: true}}
                    className="mb-10 sm:mb-14 lg:mb-16"
                >
                    <span className="text-blueCustom font-bold">Mūs virza pārliecība, ka sapņi ir jāīsteno.</span> Mūsu
                    uzņēmums tika dibināts ar vienu mērķi, palīdzēt cilvēkiem
                    īstenot viņu redzējumu, paralēli padarot šo procesu vienkāršu un viegli saprotamu, kā arī bez liekas
                    piepūles. Mēs lepojamies ar
                    to, ka piedāvājam inovatīvus risinājumus, attīstāmies līdzi laikam, un patstāvīgi sniedzam izcilu
                    apkalpošanu. Mēs cenšamies radīt neaizmirstamu pieredzi, kas palīdzēs pārvērst <span
                    className="text-blueCustom font-bold">Jūsu Sapņus</span> realitātē, dodot iespēju
                    maksimāli palielināt Jūsu potenciālo izaugsmi.
                </motion.p>
                <motion.p
                    initial={{opacity: 0, x: -100}}
                    whileInView={{opacity: 1, x: 0}}
                    transition={{duration: 1, delay: 0.4}}
                    viewport={{once: true}}
                    className="text-blueCustom font-semibold"
                >
                    Mūsu mērķis: iedvesmot, ieviest inovatīvus risinājumus un pārsniegt cerības katrā mūsu projektā.
                </motion.p>
            </div>
        </div>
    );
}

export default AboutCompany;


import React from "react";
import {Link} from 'react-scroll';
import logo from "../img/lucidweb_logo.png";
import {FaChartLine, FaCheckCircle, FaCog, FaUsers} from "react-icons/fa";
import {motion} from "framer-motion";

const IconWithTooltip = ({icon, description, delay}) => (
    <motion.div
        initial={{opacity: 0, y: 50}}
        whileInView={{opacity: 1, y: 0}}
        transition={{duration: 0.5, delay}}
        viewport={{once: true}}
        className="relative group flex items-center justify-center"
    >
        <div
            className="w-16 h-16 bg-gradient-to-br from-fuchsia-500 via-gray-450 to-emerald-600 rounded-full flex items-center justify-center transform transition-transform duration-300 group-hover:scale-110">
            {icon}
        </div>
        <div
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full mt-2 px-4 py-2 bg-gray-800 text-white text-sm font-semibold rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            {description}
        </div>
    </motion.div>
);

const Home = () => {
    return (
        <div className="flex flex-col items-center mt-16 pt-20 relative z-10">
            <div
                className="w-40 h-44 sm:w-28 sm:h-28 md:w-[28rem] md:h-[30rem] rounded-full flex items-center justify-center">
                <img src={logo} alt="LucidWeb Logo" className="w-full h-full object-cover rounded-full"/>
            </div>
            <div className="text-2xl md:text-5xl text-white mb-8 font-bold">
                Lucid<span className="text-blueCustom">Web</span>
            </div>

            <div className="flex flex-col items-center mb-16 relative z-10">
                <motion.div
                    className="hidden md:flex items-center justify-center mb-8"
                    initial={{opacity: 0, y: 50}}
                    whileInView={{opacity: 1, y: 0}}
                    viewport={{once: true}}
                    transition={{duration: 0.8, ease: "easeInOut"}}
                >
                    <Link
                        to="AboutUs"
                        smooth={true}
                        duration={800}
                        className="px-2 md:px-8 lg:px-12 sm:py-3 bg-blue-400 hover:bg-blue-500 text-white rounded-l-3xl font-bold cursor-pointer text-sm text-center sm:text-base duration-500 ease-in-out"
                    >
                        Par Mums
                    </Link>
                    <Link
                        to="AboutCompany"
                        smooth={true}
                        duration={800}
                        className="px-2 md:px-8 lg:px-12 sm:py-3 bg-emerald-500 hover:bg-emerald-600 text-white font-bold cursor-pointer text-sm text-center sm:text-base duration-500 ease-in-out"
                    >
                        Par Kompāniju
                    </Link>
                    <Link
                        to="WhatWeOffer"
                        smooth={true}
                        duration={800}
                        className="px-2 md:px-8 lg:px-12 py-2.5 sm:py-3 bg-indigo-500 hover:bg-indigo-600 text-white rounded-r-3xl font-bold cursor-pointer text-sm text-center sm:text-base duration-500 ease-in-out"
                    >
                        Pakalpojumi
                    </Link>
                </motion.div>

                <div className="flex space-x-4">
                    <IconWithTooltip
                        icon={<FaCog className="text-white text-2xl"/>}
                        description="Inovācija"
                        delay={0.2}
                    />
                    <IconWithTooltip
                        icon={<FaChartLine className="text-white text-2xl"/>}
                        description="Izaugsme"
                        delay={0.4}
                    />
                    <IconWithTooltip
                        icon={<FaUsers className="text-white text-2xl"/>}
                        description="Sabiedrība"
                        delay={0.6}
                    />
                    <IconWithTooltip
                        icon={<FaCheckCircle className="text-white text-2xl"/>}
                        description="Mērķi"
                        delay={0.8}
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;
